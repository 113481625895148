.p-outer {
  width: 100%;
  padding: 10px;
  background-color: #f8f8f8;
}

.p-title {
  color: #444444;
  font-size: 16px;
  font-weight: 400;
  margin-bottom: 10px;
  text-transform: uppercase;
}

.p-success {
  border-radius: 5px;
  background-color: #f2f8f3;
  border: 2px solid #bcd7c0;
  padding: 10px 15px 15px 15px;
  display: flex;
  justify-content: flex-start;

  &-content {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding-left: 10px;

    & > :first-child {
      font-size: 14px;
      font-weight: 500;
    }

    & > :last-child {
      font-size: 14px;
      font-weight: 400;
    }
  }
}

.p-check {
  fill: none;
  color: #0a801f;
  max-width: 100%;
  max-height: 100%;
  width: 24px;
  height: 24px;
  display: block;
  box-sizing: border-box;
  stroke: currentColor;
}

.p-prompt-box {
  background-color: #ffffff;
  border: 2px solid #dedede;
  border-radius: 5px;
  // width: 100%;
  padding: 20px;

  display: flex;
  flex-direction: column;
  justify-content: center;
}

.p-heading1 {
  font-size: 14px;
  font-weight: 600;
  margin: 0;
  margin-bottom: 10px;
  background-color: inherit;

  text-align: start;
}

.p-validating {
  margin: auto;
}

.p-message {
  color: rgb(148, 108, 0);
  font-size: 14px;
  font-weight: 400;
  margin: 0;
  margin-bottom: 10px;

  text-align: start;

  &.suggestion {
    font-size: 12px;
    color: #000000;
  }
}

.p-addressInput {
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;
}

.p-addressLine, .p-infoMessage {
  font-size: 14px;
  font-weight: 400;
  display: inline-block;
  line-height: 20px;
}

.p-addressLine.suggestion {
  font-weight: 500;
  color: rgb(148, 108, 0);
}

.p-infoMessage {
  color: rgb(112, 112, 122);
}